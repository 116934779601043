@font-face {
    font-family: museo-sans;
    src: local('museo-sans'), url(./fonts/MuseoSans_500.otf);
}

@font-face {
    font-family: lato;
    src: local('lato'), url(./fonts/lato-regular.ttf);
}

/* Removes the blue box around the tabs when clicked on */
/*.css-1e8fg3o:focus {
    box-shadow: 0 0 0 4px transparent !important
} */
